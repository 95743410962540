import React from 'react';
import '../main/main.css';

const Main = function(){

    const deadline = '2025-05-10';
    const dateParts = deadline.split('-') ;
    const [year, month , day] = dateParts;
    const countDownDate = new Date(year,month -1,day).getTime();
    const setInterval = function(){
        const realTime = new Date().getTime();
        const period = countDownDate - realTime;
        const days = Math.floor(period / (1000*60*60*24));
        
        if(period < 0){
            return '0';
        }
        return days;
    }

    return(
        <div className="main_block">
            <div className="jubilee_side">
                <div className="jubilee_position">
                </div>
            </div>
            <div className="promotion_timer">
                <div className="timer">
                    <span className="title">Дней до старта :</span>
                    <span id='days' className='days_num'>{setInterval()}</span>
                </div>
            </div>
        </div>
    )
}
export default Main;